/*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-11-30 18:10:44
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-06 13:49:57
 * @FilePath: \yda_web_manage\src\utils\selectorListFormate.js
 * @Description: 格式化人员部门选择器列表数据
 * */
import { transformPeople } from '@/apis/businessManage'
// 格式化列表数据
export const formateListData = (res, type = 1) => {
  // console.log('接收到的参数的值', res, type)
  // type:
  // 1 部门/人员
  // 2 印章
  let department = []
  let personnel = []
  let seal = []
  switch (type) {
    case 1:
      //部门
      if (res.data.departmentList && res.data.departmentList.length !== 0) {
        department = res.data.departmentList.map(item => {
          item.islower = true
          item.dataName = item.departmentName
          item.dataType = 2
          item.dataId = item.departmentId
          item.members = item.members ?? 0
          item.icon = require('@/assets/images/checkStaff/level.png')
          return item
        })
      }
      //人员
      if (res.data.staffList && res.data.staffList.length !== 0) {
        personnel = res.data.staffList.map(item => {
          item.islower = false
          item.dataName = item.staffName
          item.dataType = 1
          item.dataId = item.staffId
          item.icon = item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')
          // item.departmentNames = item.departmentNames
          return item
        })
      }
      break
    case 2:
      //印章
      if (type === 2) {
        seal = res.map(item => {
          item.islower = false
          item.dataName = item.sealName
          item.dataType = 1
          item.dataId = item.id
          item.icon =
            item.status == 2
              ? require('@/assets/images/business/disabled_seal.png')
              : require('@/assets/images/business/active_seal.png')
          return item
        })
      }
  }

  return [...department, ...personnel, ...seal]
}

//处理部门转化人员
export const transformData = async arr => {
  console.log('接收到格式话的数组', arr)
  if (!arr & !arr.length) return
  console.log('arr的值', arr)
  let deptIds = []
  let staffList = []
  let depToStaff = []
  arr.forEach(ele => {
    if (ele.dataType === 2) {
      //部门
      deptIds.push(ele.dataId)
    } else {
      staffList.push(ele)
    }
  })

  await transformPeople({ deptIds })
    .then(res => {
      console.log('部门转人员', res)
      if (res.success && res.data?.length) {
        depToStaff = res.data.map(ele => {
          ele.dataName = ele.staffName
          ele.dataId = ele.staffId
          ele.dataType = 1
          ele.icon = ele.avatarId ? ele.fullAvatar : require('@/assets/images/default-avatar.png')
          return ele
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
  staffList = [...staffList, ...depToStaff]
  console.log('去重前人员数组', staffList)
  for (var i = 0; i < staffList.length - 1; i++) {
    for (var j = i + 1; j < staffList.length; j++) {
      if (staffList[i].dataId == staffList[j].dataId) {
        staffList.splice(j, 1)
        j--
      }
    }
  }
  console.log('去重后的人员数组', staffList)
  return staffList
}
